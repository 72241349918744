import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActionAreaProps,
  CardContent,
  CircularProgress,
  Grid,
  GridSize,
  Typography,
} from '@material-ui/core';
import { ReactElement, useState } from 'react';
import { EmptyContainer, FileIcon, OverflowTooltip } from '../../components';
import { PublicationSupportingDocument } from '../../typings';
import { client } from '../../utils/client';
import DownloadIcon from '@mui/icons-material/Download';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    color: 'white',
  },
}));

interface PublicationSupportingDocumentsProps {
  documents: Array<PublicationSupportingDocument>;
  publicationId: string;
}

/**
 * @description Renders a Publication's history as a timeline of document versions.
 *
 */
const PublicationSupportingDocuments = (props: PublicationSupportingDocumentsProps) => {
  const { documents, publicationId } = props;
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const handleRequestDocument = (doc: PublicationSupportingDocument) => async () => {
    try {
      setLoading(true);
      enqueueSnackbar('Requesting download...', { variant: 'success', autoHideDuration: 3000 });

      const data = await client.getSupportingDocumentDownloadLink({
        documentId: doc.id,
        publicationId,
      });

      window.location.href = client.getDocumentDownloadUrl(data.downloadUrl);
    } catch (error) {
      enqueueSnackbar(`An error occured when downloading the supporting document: ${error}`, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Box mr={0.5}>{loading && <CircularProgress size={14} className={classes.buttonProgress} />}</Box>
      <Typography variant="h5" style={{ paddingBottom: '16px' }}>
        Supporting Documents {documents?.length ? `(${documents.length})` : ''}
      </Typography>
      <Grid container spacing={2}>
        {documents.map((doc) => (
          <Grid item xs={12}>
            <RelatedDocumentCard document={doc} loading={loading} handleOnClick={handleRequestDocument(doc)} />
          </Grid>
        ))}
        {documents.length === 0 && (
          <Grid item xs={12}>
            <EmptyContainer icon={'empty'} message={'Nothing to show.'} />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

interface DocumentCardProps {
  document: PublicationSupportingDocument;
  loading: boolean;
  handleOnClick: CardActionAreaProps['onClick'];
}

/**
 * @description Renders a Related Document list item with a download button
 *
 */

const RelatedDocumentCard = (props: DocumentCardProps) => {
  const items: Array<{ header: string; size: GridSize; value?: string | ReactElement }> = [
    { header: 'Title', value: props.document.title, size: 5 },
    {
      header: 'File Type',
      value: (
        <FileIcon size={36} fileExtension={props.document.fileExtension} alternateText={props.document.fileType} />
      ),
      size: 1,
    },
    { header: 'Size', value: props.document.fileSize?.toString(), size: 2 },
  ];

  const content = (
    <Grid container spacing={2}>
      {items.map(({ header, value, size }) => (
        <Grid key={header} item xs={size}>
          <Typography color="textSecondary" variant="caption">
            {header}
          </Typography>
          <Typography variant="body1">{header === 'Size' ? formatBytes(Number(value)) : value}</Typography>
        </Grid>
      ))}
      <Grid item xs={4}>
        <Box sx={{ display: 'flex', alignItems: 'center', height: '100%', justifyContent: 'flex-end' }}>
          <ButtonGroup>
            <Button
              color="secondary"
              variant="outlined"
              style={{
                maxHeight: '3em',
              }}
              disabled={!props.document.id}
              onClick={props.handleOnClick}
            >
              <DownloadIcon fontSize="small"></DownloadIcon>&nbsp;File
            </Button>
          </ButtonGroup>
        </Box>
      </Grid>
    </Grid>
  );

  return (
    <Card>
      <CardContent>{content}</CardContent>
    </Card>
  );
};

function formatBytes(bytes: number, decimals = 2) {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export default PublicationSupportingDocuments;
