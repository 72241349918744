import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Dialog,
  FormControl,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { PackageFile, PublicationDetailResponse, PublicationTask, TaskCloseVerdicts } from '../../typings';
import { client } from '../../utils/client';
import { DragAndDropZone, FileUploadItem } from '../DragAndDropZone';
import useStyles from './styles';
import { useAsyncFn, useEffectOnce } from 'react-use';
import { getDownloadAllDetails, getDownloadDetails } from '../../utils/format';
import DownloadIcon from '@mui/icons-material/Download';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import FileOpenIcon from '@mui/icons-material/FileOpen';

const placeholder =
  'Enter a Comment (optional) e.g. I fixed a typo and provided my comments in the latest document draft.';

type CompleteTaskModalProps = {
  task: PublicationTask;
  open: boolean;
  onClose: () => any;
  onTaskComplete: () => any;
};

/**
 * @description Renders modal allowing user to complete a Task.
 * @param props the component properties
 */
export const CompleteTaskModal = (props: CompleteTaskModalProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isReviewOrApprovalTask, setIsReviewOrApprovalTask] = useState<boolean>(false);
  const { open, onClose, onTaskComplete, task } = props;
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [uploadedFiles, setUploadedFiles] = useState<Array<FileUploadItem>>([]);
  const [comments, setComments] = useState<string | undefined>(task.comments);
  const [commentsError, setCommentsError] = useState<string>();

  const isLoadingFiles = uploadedFiles.some((file) => file.loading);

  const { showAgreeButton, showApprovalButtons, showComments, showCompleteButton, showUpload, showConsentLanguage } =
    getTypesRenderingOptions(task.type);

  const [{ value: response }, doGetPublicationDetails] = useAsyncFn(() => {
    return client.getPublication({ id: props.task.publicationReference?.id });
  }, [props.task.publicationReference?.id]);

  const { publication } = response || ({} as PublicationDetailResponse);

  useEffectOnce(() => {
    if (props.task.publicationReference?.id && (props.task.type === 'CM_Review' || props.task.type === 'CM_Approval')) {
      setIsReviewOrApprovalTask(true);
      doGetPublicationDetails();
    }
  });

  const docVersion = publication?.latestAvailableDocumentVersion;

  const { handleDownload, handleCheckout, hasDownloadUrl, hasCheckoutUrl } = getDownloadDetails(docVersion);

  const { handleDownloadAll } = getDownloadAllDetails(response?.packageFiles as Array<PackageFile>, docVersion);

  const hasPackageFiles = response?.packageFiles && response?.packageFiles.length > 0;

  /**
   * Handles when a user updates the comment field
   * @param event the comment change event
   */
  const handleChangeComment: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const newComment = event.target.value;

    if (!comments && newComment) {
      setCommentsError(undefined);
    }

    setComments(newComment);
  };

  /**
   * Handles when a user click the Cancel button
   */
  const handleCancel = () => {
    onClose();
  };

  /**
   * Closes the task with an "Reject" status
   */
  const handleReject = async () => {
    if (!comments) {
      setCommentsError('You must set a comment when rejecting.');
      return;
    }

    await handleClose('REJECTED');
  };

  /**
   * Closes the task with an "Complete" status
   */
  const handleComplete = async () => {
    await handleClose('COMPLETED');
  };

  /**
   * Closes the task with an "Approved" status
   */
  const handleApprove = async () => {
    await handleClose('APPROVED');
  };

  /**
   * Generic method handling when any task type is closed
   * @param verdict the task close verdict
   */
  const handleClose = async (verdict: TaskCloseVerdicts) => {
    try {
      setLoading(true);
      await client.closeTask({ id: props.task.id, comments, uploadedFiles, verdict });
      enqueueSnackbar('Task has been closed.', { variant: 'success' });
      onTaskComplete();
    } catch (error) {
      enqueueSnackbar(`An error occured when closing the task: ${error}`, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const buttonDisabled = loading || isLoadingFiles;

  return (
    <Dialog classes={{ paper: classes.dialogPaper }} open={open} onClose={onClose}>
      <Grid container spacing={2}>
        <Grid item xs={isReviewOrApprovalTask ? 8 : 12}>
          <Typography data-testid="complete-task-header" variant="h4">
            {task.subject}
          </Typography>
        </Grid>
        {isReviewOrApprovalTask && (
          <Grid item xs={4}>
            <Box height="100%" width="100%" display="flex" justifyContent="flex-end" alignItems="center">
              <ButtonGroup>
                <Tooltip title="Download Publication Document" placement="top">
                  <Button
                    color="secondary"
                    variant="outlined"
                    disabled={!hasDownloadUrl}
                    onClick={handleDownload}
                    style={{ maxHeight: '3em' }}
                  >
                    <DownloadIcon fontSize="small"></DownloadIcon>
                  </Button>
                </Tooltip>
                <Tooltip title="Download Publication Document & Package Files" placement="top">
                  <Button
                    color="secondary"
                    variant="outlined"
                    disabled={!hasPackageFiles || !hasDownloadUrl}
                    onClick={handleDownloadAll}
                    style={{ maxHeight: '3em' }}
                  >
                    <AutoAwesomeMotionIcon fontSize="small"></AutoAwesomeMotionIcon>
                  </Button>
                </Tooltip>
                <Tooltip title="Open Publication Document" placement="top">
                  <Button
                    color="secondary"
                    variant="outlined"
                    disabled={!hasCheckoutUrl}
                    onClick={handleCheckout}
                    style={{ maxHeight: '3em' }}
                  >
                    <FileOpenIcon fontSize="small"></FileOpenIcon>
                  </Button>
                </Tooltip>
              </ButtonGroup>
            </Box>
          </Grid>
        )}
      </Grid>
      <Typography>{task.directions}</Typography>

      {showConsentLanguage && task.consentLanguage && (
        <Typography component="div">
          <div dangerouslySetInnerHTML={{ __html: task.consentLanguage }} />
        </Typography>
      )}

      {showComments && (
        <FormControl fullWidth>
          <TextField
            disabled={loading}
            label={'Comments'}
            id={`comments-${task.id}`}
            error={!!commentsError}
            helperText={commentsError}
            onChange={handleChangeComment}
            value={comments}
            placeholder={placeholder}
            fullWidth
            multiline
            minRows={4}
            color={'secondary'}
            variant="outlined"
          />
        </FormControl>
      )}

      {showUpload && (
        <DragAndDropZone uploadedFiles={uploadedFiles} setUploadedFiles={setUploadedFiles} disabled={loading} />
      )}

      <div className={classes.buttonsSection}>
        <div className={classes.wrapper}>
          <Button
            className={classes.cancelButton}
            onClick={handleCancel}
            variant="outlined"
            data-testid={'cancel-button'}
          >
            Cancel
          </Button>
        </div>
        {showAgreeButton && (
          <Button
            disabled={buttonDisabled}
            onClick={handleComplete}
            color="secondary"
            variant="contained"
            data-testid={'agree-button'}
          >
            {isLoadingFiles ? 'Uploading...' : 'Agree'}
          </Button>
        )}
        {showCompleteButton && (
          <div className={classes.wrapper}>
            <Button
              disabled={buttonDisabled}
              onClick={handleComplete}
              color="secondary"
              variant="contained"
              data-testid={'complete-button'}
            >
              {isLoadingFiles ? 'Uploading...' : 'Complete'}
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
        )}
        {showApprovalButtons && (
          <>
            <Button
              disabled={buttonDisabled}
              className={classes.rejectButton}
              onClick={handleReject}
              variant="outlined"
              data-testid={'reject-button'}
            >
              {isLoadingFiles ? 'Uploading...' : 'Reject'}
            </Button>
            <Button
              disabled={buttonDisabled}
              onClick={handleApprove}
              color="secondary"
              variant="contained"
              data-testid={'approve-button'}
            >
              {isLoadingFiles ? 'Uploading...' : 'Approve'}
            </Button>
          </>
        )}
      </div>
    </Dialog>
  );
};

/**
 * Returns if components should be displayed or hidden based on Task Type
 * @param taskType the task type
 * @returns the show/hide values
 */
const getTypesRenderingOptions = (taskType: string) => {
  let options = {
    showAgreeButton: false,
    showComments: false,
    showUpload: false,
    showCompleteButton: false,
    showConsentLanguage: false,
    showApprovalButtons: false,
  };

  switch (taskType) {
    case 'PP_Consent':
      options.showAgreeButton = true;
      options.showConsentLanguage = true;
      break;
    case 'CM_Approval':
      options.showUpload = true;
      options.showComments = true;
      options.showApprovalButtons = true;
      break;
    case 'CM_Review':
    case 'PP_General':
      options.showUpload = true;
      options.showComments = true;
      options.showCompleteButton = true;
      break;
    default:
      options.showUpload = true;
      options.showComments = true;
      options.showCompleteButton = true;
  }

  return options;
};
