import { Chip } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

// sourced from here:
// https://github.com/mavens/P-PLTFM-Core/blob/develop/packages/mvn-content/main/default/lwc/cmDocumentStatusBadge/cmDocumentStatusBadge.css
const useStyles = makeStyles(() =>
  createStyles({
    blue: {
      background: '#195594',
      color: 'white',
      wordWrap: 'break-word',
      maxWidth: '100%',
      overflow: 'auto',
    },
    darkBlue: {
      background: '#16325c',
      color: 'white',
      wordWrap: 'break-word',
      maxWidth: '100%',
      overflow: 'auto',
    },
    lightBlue: {
      background: '#d8edff',
      color: '#080707',
      wordWrap: 'break-word',
      maxWidth: '100%',
      overflow: 'auto',
    },
    orange: {
      background: '#ff9e2c',
      color: 'white',
      wordWrap: 'break-word',
      maxWidth: '100%',
      overflow: 'auto',
    },
    green: {
      background: '#04844b',
      color: 'white',
      wordWrap: 'break-word',
      maxWidth: '100%',
      overflow: 'auto',
    },
    red: {
      background: '#c23934',
      color: 'white',
      wordWrap: 'break-word',
      maxWidth: '100%',
      overflow: 'auto',
    },
    grey: {
      background: '#ecebea',
      color: '#080707',
      wordWrap: 'break-word',
      maxWidth: '100%',
      overflow: 'auto',
    },
  })
);

interface StatusLabelProps {
  status: string;
  label: string;
  color: string;
  style?: any;
}

export const StatusLabel = (props: StatusLabelProps) => {
  const { label, color, style } = props;

  const classes = useStyles();

  let classNames;

  switch (color) {
    case 'blue':
      classNames = classes.blue;
      break;
    case 'dark-blue':
      classNames = classes.darkBlue;
      break;
    case 'light-blue':
      classNames = classes.lightBlue;
      break;
    case 'orange':
      classNames = classes.orange;
      break;
    case 'green':
      classNames = classes.green;
      break;
    case 'red':
      classNames = classes.red;
      break;
    case 'grey':
      classNames = classes.grey;
      break;
  }

  return <Chip title={label} size="small" className={classNames} label={label} style={style} />;
};
