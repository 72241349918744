import { AppBar as MUIAppBar, Button, Container, Link, Menu, MenuItem, Toolbar } from '@material-ui/core';
import { AccountCircle, ExpandMore } from '@material-ui/icons';
import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useToken } from '../../hooks/useToken';
import { ENABLE_MULTIORG, routes } from '../../utils/constants';
import { ChangeOrg } from '../ChangeOrg/ChangeOrg';
import { useStyles } from './styles';

const menuOptions = [
  {
    label: 'My Publications',
    paths: routes.publications.routeProps.path,
    items: [
      {
        label: 'Active',
        path: routes.publications.active.routeProps.path[0],
      },
      {
        label: 'Inactive',
        path: routes.publications.archived.routeProps.path[0],
      },
    ],
  },
  {
    label: 'My Tasks',
    paths: routes.tasks.routeProps.path,
    items: [
      { label: 'Open', path: routes.tasks.open.routeProps.path[0] },
      { label: 'Closed', path: routes.tasks.closed.routeProps.path[0] },
    ],
  },
];

const profileOptionsRoutes = [routes.profile.routeProps.path[0], routes.logout.routeProps.path[0]];

const profileOptions = [
  {
    label: '',
    paths: profileOptionsRoutes,
    masterIcon: <AccountCircle htmlColor="#3DBEE2" />,
    items: [
      {
        label: 'Profile Settings',
        path: routes.profile.routeProps.path[0],
      },
      {
        label: 'Log Out',
        path: routes.logout.routeProps.path[0],
      },
    ],
  },
];

export const AppBar = () => {
  const classes = useStyles();

  const [token] = useToken();
  const history = useHistory();

  return (
    <MUIAppBar position="sticky" className={classes.appBar}>
      <Container>
        <Toolbar>
          <div>
            <Link
              href={routes.publications.active.routeProps.path[0]}
              color={'inherit'}
              underline={'hover'}
              className={classes.homeLink}
            >
              <img src={'/kh-icon.png'} alt={'Komodo Health Logo'} height="36" />
            </Link>
          </div>
          <div style={{ flex: 1 }}>
            {!!token &&
              menuOptions.map(({ label, items, paths }, key) => (
                <MenuButton
                  key={key}
                  label={label}
                  paths={paths}
                  menuItems={items.map((item) => ({
                    label: item.label,
                    onClick: () => history.push(item.path),
                  }))}
                />
              ))}
          </div>
          {ENABLE_MULTIORG && <ChangeOrg />}
          {!!token &&
            profileOptions.map(({ label, items, paths, masterIcon }, key) => (
              <MenuButton
                key={key}
                masterIcon={masterIcon}
                label={label}
                paths={paths}
                menuItems={items.map((item) => ({
                  label: item.label,
                  onClick: () => history.push(item.path),
                }))}
              />
            ))}

          {/* {!token && (
            <Button className={classes.unselectedItem} onClick={handleLogin} style={{ fontWeight: 'bold' }}>
              Login
            </Button>
          )} */}
        </Toolbar>
      </Container>
    </MUIAppBar>
  );
};

interface MenuButtonProps {
  label: string;
  selected?: boolean;
  paths: string[];
  masterIcon?: JSX.Element;
  menuItems: Array<{ onClick: () => void; selected?: boolean; label: string }>;
}

const MenuButton = (props: MenuButtonProps) => {
  const classes = useStyles();
  const { label, menuItems, paths, masterIcon } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isPathMatch = !!useRouteMatch(paths);

  return (
    <>
      <Button
        className={isPathMatch ? classes.selectedItem : classes.unselectedItem}
        onClick={handleClick}
        endIcon={<ExpandMore />}
        style={{ fontWeight: 'bold' }}
      >
        {masterIcon}
        {label}
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {menuItems?.map((menuItem) => (
          <MenuItem
            key={menuItem.label}
            selected={menuItem.selected}
            onClick={() => {
              menuItem.onClick();
              handleClose();
            }}
          >
            {menuItem.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
