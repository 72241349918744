import { Box, Breadcrumbs, Button, ButtonGroup, Divider, Grid, Link, Tab, Tabs, Typography } from '@material-ui/core';
import { NavigateNext } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { useState } from 'react';
import { useRouteMatch } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useAsyncFn, useEffectOnce } from 'react-use';
import { BackdroppedCircularProgress, EmptyContainer, LabeledList, StatusLabel } from '../../../components';
import { PackageFile, PublicationDetailResponse } from '../../../typings';
import { client } from '../../../utils/client';
import { routes } from '../../../utils/constants';
import { getDownloadAllDetails, getDownloadDetails } from '../../../utils/format';
import PublicationHistory from '../PublicationHistory/PublicationHistory';
import PublicationSupportingDocuments from '../PublicationSupportingDocuments';
import PublicationTasks from '../PublicationTasks';
import PackageFiles from '../PackageFiles';
import { useStyles } from './styles';
import DownloadIcon from '@mui/icons-material/Download';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';

/**
 * @description Renders a page displaying PublicationDetails. Allows user to view associated Tasks, Document History, and Supporting Documents.
 *
 */
const PublicationDetails = () => {
  const match = useRouteMatch<{ id: string }>();

  const classes = useStyles();

  const [{ loading, value: response, error }, doGetPublicationDetails] = useAsyncFn(() => {
    return client.getPublication({ id: match.params.id });
  }, [match.params.id]);

  useEffectOnce(() => {
    doGetPublicationDetails();
  });

  const { publication, history, myTasks, supportingDocuments, packageFiles } =
    response || ({} as PublicationDetailResponse);

  const target = publication?.target;
  const docVersion = publication?.latestAvailableDocumentVersion;

  const { handleDownload, handleCheckout, hasDownloadUrl, hasCheckoutUrl } = getDownloadDetails(docVersion);

  const { handleDownloadAll } = getDownloadAllDetails(response?.packageFiles as Array<PackageFile>, docVersion);

  const incompleteTasks = myTasks ? myTasks?.filter((task) => task.isOpen) : undefined;

  const [tabIndex, setTabIndex] = useState<number>(0);

  const packageFilesCount = packageFiles?.length;
  const openTaskCount = incompleteTasks?.length;
  const supportingDocCount = supportingDocuments?.length;
  const totalDocCount = (supportingDocCount ?? 0) + (packageFilesCount ?? 0);

  const labeledItems = [
    { label: 'Product', value: docVersion?.products.map((product) => product.name).join(', ') },
    { label: 'Document Number', value: docVersion?.documentNumber },
    { label: 'Document Type', value: docVersion?.documentTypeLabel },
    { label: 'Document Subtype', value: docVersion?.documentSubtypeLabel },
    { label: 'Target Name', value: target?.name },
    { label: 'Target Date', value: target?.targetDate },
  ];

  const header = (
    <Grid container spacing={2}>
      {loading && <Skeleton height={30} width={600} />}
      {!loading && (
        <Grid item xs={6}>
          <Breadcrumbs separator={<NavigateNext fontSize="large" />}>
            <Link
              to={
                publication?.active
                  ? routes.publications.active.routeProps.path[0]
                  : routes.publications.archived.routeProps.path[0]
              }
              component={RouterLink}
              variant="h4"
              color="textPrimary"
              className={classes.breadcrumbAnchor}
            >
              {!publication ? '...' : `${publication?.active ? 'Active' : 'Inactive'} Publications`}
            </Link>
            <Typography variant="h4" color="textPrimary">
              {docVersion?.fullyQualifiedName ?? '...'}
              {!publication ? null : (
                <StatusLabel
                  label={docVersion?.statusLabel || docVersion?.status}
                  status={docVersion?.status}
                  color={docVersion?.statusColor}
                  style={{ marginLeft: 10 }}
                />
              )}
            </Typography>
          </Breadcrumbs>
        </Grid>
      )}
      {!loading && (
        <Grid item xs={6}>
          <Box display="flex" justifyContent="flex-end">
            <ButtonGroup>
              <Button
                color="secondary"
                variant="outlined"
                onClick={handleDownload}
                disabled={!hasDownloadUrl}
                style={{ maxHeight: '3em' }}
              >
                {<DownloadIcon fontSize="small"></DownloadIcon>}&nbsp;Pub
              </Button>

              <Button
                color="secondary"
                variant="outlined"
                onClick={handleDownloadAll}
                disabled={packageFilesCount === 0 || !hasDownloadUrl}
                style={{ maxHeight: '3em' }}
              >
                <AutoAwesomeMotionIcon fontSize="small"></AutoAwesomeMotionIcon>&nbsp;Pub & Package Files
              </Button>

              <Button
                color="secondary"
                variant="outlined"
                onClick={handleCheckout}
                disabled={!hasCheckoutUrl}
                style={{ maxHeight: '3em' }}
              >
                {<FileOpenIcon fontSize="small"></FileOpenIcon>}&nbsp;Open Pub
              </Button>
            </ButtonGroup>
          </Box>
        </Grid>
      )}
    </Grid>
  );

  if (error) {
    return <EmptyContainer icon={'error'} title={'Error when rendering Publication:'} message={error + ''} />;
  }

  return (
    <Grid container style={{ position: 'relative' }} spacing={5}>
      <BackdroppedCircularProgress open={loading} />
      <Grid item xs={12}>
        {header}
      </Grid>
      <Grid item md={6}>
        <LabeledList loading={loading} items={labeledItems} />
      </Grid>
      <Grid item xs={12}>
        <Tabs
          value={tabIndex}
          onChange={(_event, newValue: number) => setTabIndex(newValue)}
          textColor="secondary"
          indicatorColor="primary"
          classes={{
            root: classes.tabsRoot,
            indicator: classes.tabsIndicator,
            flexContainer: classes.tabsFlexContainer,
          }}
          TabIndicatorProps={{ children: <span /> }}
        >
          <DetailsTab label={'My Tasks'} index={0} count={openTaskCount} />
          <DetailsTab label={'Document History'} index={1} />
          <DetailsTab label={'Related Documents'} index={2} count={totalDocCount} />
        </Tabs>
        {publication && (
          <>
            <CustomTabPanel value={tabIndex} index={0}>
              <PublicationTasks
                tasks={myTasks}
                reloadPublication={doGetPublicationDetails}
                loading={loading}
                hasCheckoutUrl={hasCheckoutUrl}
                hasDownloadUrl={hasDownloadUrl}
                hasPackageFiles={packageFiles && packageFiles.length > 0}
                handleDownload={handleDownload}
                handleDownloadAll={handleDownloadAll}
                handleOpenIn365={handleCheckout}
                publicationReferenceId={publication.id}
              />
            </CustomTabPanel>
            <CustomTabPanel value={tabIndex} index={1}>
              <PublicationHistory history={history} />
            </CustomTabPanel>
            <CustomTabPanel value={tabIndex} index={2}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <PackageFiles packageFiles={packageFiles} />
                </Grid>
                <Grid item xs={12} style={{ paddingBottom: '6px' }}>
                  <Divider style={{ height: '0.2em' }} />
                </Grid>
                <Grid item xs={12}>
                  <PublicationSupportingDocuments documents={supportingDocuments} publicationId={publication.id} />
                </Grid>
              </Grid>
            </CustomTabPanel>
          </>
        )}
      </Grid>
    </Grid>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

type DetailsTabProps = {
  label: string;
  index: number;
  count?: number;
  disabled?: boolean;
};

const DetailsTab = (props: DetailsTabProps) => {
  const { label, index, count, disabled, ...tabProps } = props;
  const classes = useStyles();

  return (
    <Tab
      disableRipple
      classes={{ root: classes.tab }}
      label={`${label}${count !== undefined ? ` (${count})` : ''}`}
      disabled={disabled}
      {...tabProps}
      {...a11yProps(index)}
    />
  );
};

export default PublicationDetails;
