import { Grid, Typography, ButtonGroup, IconButton } from '@material-ui/core';
import { TableChart, GridOn } from '@material-ui/icons';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { BackdroppedCircularProgress, EmptyContainer, EnhancedTable, PublicationCard } from '../components';
import { InfiniteScroll } from '../components/InfiniteScroll';
import { useInfiniteScroll } from '../hooks/useInfiniteScroll';
import { PublicationListItem } from '../typings';
import { routes } from '../utils/constants';
import { StatusLabel } from '../components/StatusLabel';

type Header = {
  label: string;
  field: string;
  apiName?: string;
  sortable: boolean;
};

const defaults = {
  pageSize: 10,
  sortFieldName: 'mvn__CM_Latest_Version__r.mvn__CM_Fully_Qualified_Name__c',
  sortDirection: 'DESC',
};

const Publications = () => {
  let [useTableView, setUseTableView] = useState(localStorage.getItem('useTableView') === 'true');

  const location = useLocation();
  const showActivePublications = Boolean(useRouteMatch(routes.publications.active.routeProps));
  const handleShowCardsButton = () => {
    setUseTableView(false);
    localStorage.setItem('useTableView', 'false');
  };
  const handleShowTableButton = () => {
    setUseTableView(true);
    localStorage.setItem('useTableView', 'true');
  };

  const filters = {
    ...defaults,
    active: showActivePublications,
  };

  const { records, hasMore, loading, error, sortDirection, sortFieldName, setCursorRequest, loadMoreRef, setSortFieldName, setSortDirection } =
    useInfiniteScroll<PublicationListItem>('publications', filters);

  useEffect(() => {
    setCursorRequest(filters as any);
  }, [location]);

  const showAllActiveCompleted = !loading && !error && showActivePublications && records?.length === 0;
  const showAllArchivedCompleted = !loading && !error && !showActivePublications && records?.length === 0;
  const showError = !loading && error;
  const showData = !loading && records?.length !== 0;

  const tableHeaders: Record<string, Header> = {
    publication: {
      label: 'Publication',
      field: 'publication',
      apiName: 'mvn__CM_Latest_Version__r.mvn__CM_Fully_Qualified_Name__c',
      sortable: true,
    },
    status: {
      label: 'Status',
      field: 'status',
      apiName: 'mvn__CM_Latest_Version__r.mvn__CM_Status__c',
      sortable: true,
    },
    product: {
      label: 'Product',
      field: 'product',
      apiName: 'mvn__CM_Latest_Version__r.mvn__CM_Products__c',
      sortable: true,
    },
    documentNumber: {
      label: 'Document #',
      field: 'documentNumber',
      apiName: 'mvn__CM_Latest_Version__r.mvn__CM_Document_Number__c',
      sortable: true,
    },
    documentType: {
      label: 'Document Type',
      field: 'documentType',
      apiName: 'mvn__CM_Latest_Version__r.mvn__CM_Document_Type__c',
      sortable: true,
    },
    documentSubtype: {
      label: 'Document Subtype',
      field: 'documentSubtype',
      apiName: 'mvn__CM_Latest_Version__r.mvn__CM_Document_Subtype__c',
      sortable: true,
    },
    targetName: {
      label: 'Target Name',
      field: 'targetName',
      apiName: 'mvn__CM_Latest_Version__r.mvn__PP_Primary_Target__r.Name',
      sortable: true,
    },
    targetDate: {
      label: 'Target Date',
      field: 'targetDate',
      apiName: 'mvn__CM_Latest_Version__r.mvn__PP_Primary_Target__r.mvn__PP_Estimated_Submission_Date__c',
      sortable: true,
    },
    openTasks: {
      label: 'Open Tasks',
      field: 'openTasks',
      sortable: false,
    },
  };
  
  const tableData =
    records?.map((item) => ({
      key: item.id,
      publication: (
        <>
          <Link to={'/publications/' + item.id}>
            {item.latestAvailableDocumentVersion.fullyQualifiedName}
          </Link>
        </>
      ),
      status: (
        <>
            <StatusLabel
              label={item.latestAvailableDocumentVersion.statusLabel}
              status={item.latestAvailableDocumentVersion.status}
              color={item.latestAvailableDocumentVersion.statusColor}
            />
        </>
      ),
      product: item.latestAvailableDocumentVersion.products.map((product) => product.name).join(', '),
      documentNumber:
        item.latestAvailableDocumentVersion.documentNumberLabel || item.latestAvailableDocumentVersion.documentNumber,
      documentType:
        item.latestAvailableDocumentVersion.documentTypeLabel || item.latestAvailableDocumentVersion.documentType,
      documentSubtype:
        item.latestAvailableDocumentVersion.documentSubtypeLabel || item.latestAvailableDocumentVersion.documentSubtype,
      targetName: item.target?.name,
      targetDate: item.target?.targetDate,
      openTasks: (
        <>
          <Link to={'/publications/' + item.id}>
            {item.openTaskCount} Open Task{item.openTaskCount === 1 ? '' : 's'}
          </Link>
        </>
      ),
    })) ?? [];

  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <Typography variant="h4" style={{ lineHeight: '2em' }}>
          <Switch>
            <Route {...routes.publications.active.routeProps}>Active Publications</Route>
            <Route {...routes.publications.archived.routeProps}>Inactive Publications</Route>
          </Switch>
          <ButtonGroup disableElevation variant="contained" style={{ float: 'right' }}>
            <IconButton size="medium" color={useTableView ? 'primary' : 'secondary'} onClick={handleShowCardsButton}>
              <GridOn />
            </IconButton>
            <IconButton size="medium" color={useTableView ? 'secondary' : 'primary'} onClick={handleShowTableButton}>
              <TableChart />
            </IconButton>
          </ButtonGroup>
        </Typography>
      </Grid>
      <Grid item style={{ position: 'relative' }} xs={12}>
        <BackdroppedCircularProgress open={loading} />
        {showError && (
          <EmptyContainer icon={'error'} title={'Error when rendering Publications:'} message={error + ''} />
        )}
        {showAllActiveCompleted && <EmptyContainer icon={'check'} message={'All publications are completed!'} />}
        {showAllArchivedCompleted && <EmptyContainer icon={'empty'} message={'Nothing to show.'} />}
        {showData && records && !useTableView && (
          <>
            <Grid container spacing={4}>
              {records.map((item, key) => (
                <PublicationItem key={key} item={item} loading={loading} />
              ))}
            </Grid>
            <InfiniteScroll hasMore={hasMore} loadMoreRef={loadMoreRef} />
          </>
        )}
        {showData && records && useTableView && (
          <>
            <EnhancedTable
              data={tableData}
              headers={Object.values(tableHeaders)}
              sortDirection={sortDirection?.toLowerCase() as any}
              sortFieldName={Object.values(tableHeaders).find((header) => header.apiName === sortFieldName)?.field as string}
              onSortClick={(field, direction) => {
                setSortFieldName(tableHeaders[field].apiName as string);
                setSortDirection(direction);
              }}
            />
            <InfiniteScroll hasMore={hasMore} loadMoreRef={loadMoreRef} />
          </>
        )}
      </Grid>
    </Grid>
  );
};

type PublicationItemProps = {
  item: PublicationListItem;
  loading: boolean;
};

const PublicationItem = ({ item, loading }: PublicationItemProps) => {
  const { id, target, openTaskCount, latestAvailableDocumentVersion } = item;
  const {
    fullyQualifiedName,
    products,
    documentNumber,
    documentNumberLabel,
    documentType,
    documentTypeLabel,
    documentSubtype,
    documentSubtypeLabel,
    statusLabel,
    status,
    statusColor,
  } = latestAvailableDocumentVersion;

  return (
    <Grid item style={{ width: '100%' }} md={6}>
      <PublicationCard
        id={id}
        loading={loading}
        fullyQualifiedName={fullyQualifiedName}
        productName={products.map((product) => product.name).join(', ')}
        documentNumber={documentNumberLabel || documentNumber}
        documentType={documentTypeLabel || documentType}
        documentSubtype={documentSubtypeLabel || documentSubtype}
        targetName={target?.name}
        targetDate={target?.targetDate}
        openTaskCount={openTaskCount}
        statusLabel={statusLabel || status}
        statusColor={statusColor}
        status={status}
      />
    </Grid>
  );
};

export default Publications;
